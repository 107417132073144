import React from "react"
import Box from "@mui/material/Box";
import {AgGridReact} from "ag-grid-react";
import {ColumnDef} from "./column";
import PropTypes from "prop-types";
import LOCALE_JA from "../../../../../../../resources/aggrid/locale.ja";

const styles = {
    root: {
        width: '100%',
        height: '155px',
    }
}

const ReportUserSummaryOtherView = React.memo(({data}) => {

    return (
        <Box style={styles.root}>
            <AgGridReact
                localeText={LOCALE_JA}
                className={'ag-theme-balham'}
                columnDefs={ColumnDef}
                rowData={data}
            />
        </Box>
    )
})

ReportUserSummaryOtherView.propTypes = {
    data: PropTypes.array.isRequired,
}

export default ReportUserSummaryOtherView
