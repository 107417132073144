import React, {useCallback, useEffect, useState} from "react"
import Box from "@mui/material/Box";
import {AgGridReact} from "ag-grid-react";
import { ColumnDefs, DefaultColDef} from "./column"
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import {Place as PlaceIcon} from "@mui/icons-material";
import MapView from "./map"
import _ from "lodash";
import LOCALE_JA from "../../../../../resources/aggrid/locale.ja";

const styles = {

}

const MasterGroupFacilityView = ({data, viewMode, onMapClick}) => {

    const [lngLat, setLngLat] = useState()
    const [selectMapData, setSelectMapData] = useState()
    //
    // const onMapIcon = useCallback((data) => {
    //     console.log(viewMode)
    //     if (_.size(viewMode) > 1 ) {
    //         onMapClick(data)
    //     } else {
    //         setLngLat({lng: data.longitude, lat: data.latitude})
    //     }
    // }, [viewMode, onMapClick])

    useEffect(() => {
        console.log("[SelectMapData]", selectMapData, viewMode, onMapClick)
        if (_.size(viewMode) > 1) {
            onMapClick(selectMapData)
        }
    }, [selectMapData, viewMode, onMapClick])

    const rendererMapIcon = useCallback((params) => {
        return (
            <>
                {params.data.latitude && params.data.longitude && <IconButton
                    onClick={() => setSelectMapData(params.data)}
                    size="small">
                    <PlaceIcon size="small" />
                </IconButton>}
            </>
        )
    }, [viewMode])


    return (
        <Box style={{flexGrow: 1}}>
            <AgGridReact
                localeText={LOCALE_JA}
                className={'ag-theme-balham'}
                columnDefs={ColumnDefs}
                defaultColDef={DefaultColDef}
                rowData={data}
                components={{
                    rendererMapIcon,
                }}
            />
            {_.size(viewMode) === 1 && selectMapData && <MapView data={selectMapData} onClose={() => setSelectMapData(null)} />}
        </Box>
    )
}

MasterGroupFacilityView.propTypes = {
    data: PropTypes.array.isRequired,
    viewMode: PropTypes.array,
    onMapClick: PropTypes.func.isRequired,
}

export default MasterGroupFacilityView
